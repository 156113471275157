<template>

  <v-container grid-list-xs>
    <v-card width="600" height="auto"  class="mx-auto pa-2  rounded-lg">
      <v-layout class="mt-2">
        <v-flex>
          <v-btn :disabled="this.$getters.userInfo.grade > 'UT03'" block href="/compo">컴포넌트 테스트</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn :disabled="this.$getters.userInfo.grade > 'UT03'" block href="/alert/AlertDataTest">알림 데이터 테스트</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="50%" href="/alert/AlertSet2">알림수정</v-btn>
          <v-btn width="50%" href="/homedash">대시보드</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="25%" href="/sensormap2">센서맵2</v-btn>
          <v-btn width="25%" href="/deodorizer/jydeodorizer">진영분무기</v-btn>
          <v-btn width="25%" href="/esg/monitorEsg2">ESG 모니터링</v-btn>
          <v-btn width="25%" href="/esg/SimulationEsg">ESG 시뮬</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="25%" href="/deodorizer/jydeodorizer2">진영_api</v-btn>
          <v-btn width="25%" href="/device/monitorsensor2">센서모니터링</v-btn>
          <v-btn width="25%" href="/alert/alertset">알림설정</v-btn>
          <v-btn width="25%" href="/FarmMap">농장/사업장 맵</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn block href="/SensorMap">센서 맵</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn block href="/home">돈방/위치모니터링( 현황 / 요약 / 일지 )</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="25%" href='/farminfo/registerinfo/company' :disabled="this.$getters.userInfo.grade > 'UT01'">경영체 </v-btn>
          <v-btn width="25%" href='/farminfo/registerinfo/farm' :disabled="this.$getters.userInfo.grade > 'UT01'">농장 </v-btn>
          <v-btn width="25%" href='/farminfo/registerinfo/building' :disabled="this.$getters.userInfo.grade > 'UT01'">축사 </v-btn>
          <v-btn width="25%" href='/farminfo/registerinfo/room' :disabled="this.$getters.userInfo.grade > 'UT01'">돈방 </v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="50%" href='/farminfo/dealCompany' :disabled="this.$getters.userInfo.grade > 'UT05'">거래처 등록</v-btn>
          <v-btn width="50%" href='/farminfo/listDealCompanies' :disabled="this.$getters.userInfo.grade > 'UT05'">거래처 목록</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn block href='/farminfo/registerinfo/room' :disabled="this.$getters.userInfo.grade > 'UT05'">돈방/위치 등록 / 목록 / 센서현황</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn block href='/user/registeruser' :disabled="this.$getters.userInfo.grade > 'UT03'">사용자 정보 관리</v-btn>
       </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="33%" href='/device/listsensoronoff' >센서 ON/OFF</v-btn>
          <v-btn width="33%" href='/device/monitorsensor' >센서 DATA</v-btn>
          <v-btn width="34%" href='/device/registerdevice' :disabled="this.$getters.userInfo.grade > 'UT05'">장치 등록</v-btn>
       </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="25%"  href='/monitoring/summary' >모니터링 요약</v-btn>
          <v-btn width="25%" href='/monitoring/cardroominfo' >지표 조회</v-btn>
          <v-btn width="25%" href='/dailyrecord/totalevents' >관리일지</v-btn>
          <v-btn width="25%" href='/dailyrecord/dailytransfer' >일별이동</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn width="33%"  href='/device/settingcontrol' >팬 설정.출력 현황</v-btn>
          <v-btn width="33%"  href='/control/controlsummary' :disabled="this.$getters.userInfo.grade > 'UT01'">제어모니터 </v-btn>
          <v-btn width="34%"  href='/control/controltable' >계절.체중별 세팅</v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="mt-2">
        <v-flex>
          <v-btn block href='/monitoring/wattagedetail' >전력량조회</v-btn>
        </v-flex>
      </v-layout>

      <!-- <v-layout class="mt-2">
        <v-flex>
          <v-btn block href='/setup' >제어 모니터링</v-btn>
        </v-flex>
      </v-layout> -->

      <v-layout class="mt-2">
        <v-flex>
          <v-btn block href='/comp_test' >Component Test Page</v-btn>
        </v-flex>
      </v-layout>


    </v-card>
  </v-container>



</template>

<script>
export default {
  name: 'ManMenu',

  components: {
    },
  method: {
    },
}
</script>
